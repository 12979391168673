import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import { Dots } from 'app/components/Common/Dots'
import { Line } from 'app/components/Common/Line'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
  pretitle?: string
  title?: string
}

export const Spots = memo(function Spots({ items, pretitle, title }: Props) {
  if (items.length < 1) {
    return null
  }

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: items.length > 1 ? true : false,
    drag: true,
    slides: {
      perView: 2.25,
      spacing: 60,
    },
    breakpoints: {
      '(max-width: 991px)': {
        slides: {
          perView: 1.5,
          spacing: 10,
        },
      },
    },
  })

  return (
    <Container>
      {pretitle ? (
        <FadeInUp>
          <Line />
          <Pretitle>{pretitle}</Pretitle>
        </FadeInUp>
      ) : null}
      {title ? <Title text={title} /> : null}

      <Slider ref={sliderRef} className="keen-slider">
        {items.map((item, index) => (
          <Slide className="keen-slider__slide" key={index}>
            <Item {...item} />
          </Slide>
        ))}
      </Slider>

      <LeftArrow>
        <FadeInUp>
          <Arrow
            direction="L"
            onClick={(e) => {
              instanceRef.current?.prev()
            }}
            variant="light"
          />
        </FadeInUp>
      </LeftArrow>
      <RightArrow>
        <FadeInUp>
          <Arrow
            direction="R"
            onClick={(e) => {
              instanceRef.current?.next()
            }}
            variant="light"
          />
        </FadeInUp>
      </RightArrow>

      <StyledDots width={6} height={9} />
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  margin: 10vw 0 15vw 10vw;

  @media (max-width: 991px) {
    margin: 70px 0 70px 30px;
  }
`

const Pretitle = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.83vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.69vw;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  z-index: 1;

  @media (max-width: 1439px) {
    font-size: 10px;
    margin-left: 12px;
  }
`

const Title = styled(AnimatedTitle)`
  position: relative;
  margin-top: 2.08vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 4.16vw;
  line-height: 1.2;
  z-index: 1;

  @media (max-width: 991px) {
    font-size: 28px;
    margin-top: 20px;
  }
`

const Slider = styled.div`
  position: relative;
  width: 100%;
  margin-top: 3.3vw;
  z-index: 1;

  @media (max-width: 991px) {
    margin-top: 20px;
  }
`

const Slide = styled.div`
  width: 37.91vw;
  height: 47.29vw;
  aspect-ratio: 0.8;

  @media (max-width: 991px) {
    height: 90vw;
  }
`

const LeftArrow = styled.div`
  position: absolute;
  top: 60%;
  left: 0;
  z-index: 3;
  transform: translate(-50%, -50%);
`

const RightArrow = styled.div`
  position: absolute;
  top: 60%;
  right: 10vw;
  transform: translate(50%, -50%);
  z-index: 3;
`

const StyledDots = styled(Dots)`
  position: absolute;
  top: 0;
  right: 0;
`
