import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { LocationPin } from 'app/components/Icons'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  address?: string
  googleMapsURL?: string
  description?: string
  languageCode: string
  image?: ImageProps
  siteName?: string
  subtitle?: string
  title?: string
}

export const Map = memo(function Map({
  address,
  description,
  googleMapsURL,
  languageCode,
  image,
  siteName,
  subtitle,
  title,
}: Props) {
  return (
    <Container>
      <Heading>
        <FadeInUp>
          <Line />
        </FadeInUp>
        {title ? <Title text={title} /> : null}
        {subtitle ? (
          <FadeInUp>
            <Subtitle>{subtitle}</Subtitle>{' '}
          </FadeInUp>
        ) : null}
      </Heading>

      <Wrapper>
        <Left>
          <FadeInUp>
            <StyledImage {...image} />
          </FadeInUp>
        </Left>
        <Right>
          {siteName ? (
            <FadeInUp>
              <SiteName>{siteName}</SiteName>
            </FadeInUp>
          ) : null}
          {address ? (
            <FadeInUp>
              <a href={googleMapsURL} target="_blank">
                <Address>
                  <LocationPin />
                  {address}
                </Address>
              </a>
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
          {googleMapsURL ? (
            <FadeInUp>
              <StyledButton
                label={useVocabularyData('open-google-maps', languageCode)}
                URL={googleMapsURL}
                rel="noreferrer"
                target="_blank"
                variant="dark"
              />
            </FadeInUp>
          ) : null}
        </Right>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  padding-top: 10vw;

  @media (max-width: 991px) {
    padding: 60px 30px;
  }
`

const Heading = styled.div`
  padding: 0 10vw 4.16vw;

  @media (max-width: 991px) {
    padding: 0;
  }
`

const Title = styled(AnimatedTitle)`
  margin-top: 2.08vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: bold;
  font-size: 2.77vw;
  line-height: 1.15;

  @media (max-width: 991px) {
    font-size: 28px;
    margin-top: 20px;
  }
`

const Subtitle = styled.div`
  margin-top: 0.27vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 1.38vw;

  @media (max-width: 1439px) {
    margin-top: 4px;
  }

  @media (max-width: 991px) {
    font-size: 18px;
    margin-top: 20px;
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 991px) {
    display: block;
    padding: 0;
  }
`

const Left = styled.div`
  width: 62%;

  @media (max-width: 991px) {
    width: 100vw;
    margin-left: -30px;
    margin-top: 30px;
  }
`

const StyledImage = styled(Image)`
  position: relative;
  display: flex;
  width: 100%;
  height: 34.72vw;
  overflow: hidden;
  border-radius: 0 0.69vw 0.69vw 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 3;
  }

  @media (max-width: 991px) {
    height: 30vh;
    margin-top: 10px;
    border-radius: 0;
  }
`

const Right = styled.div`
  position: relative;
  width: 38%;
  padding: 0 10vw 0 6.5vw;

  @media (max-width: 991px) {
    width: 100%;
    padding: 30px 0;
  }
`

const SiteName = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 1.38vw;

  @media (max-width: 991px) {
    font-size: 22px;
  }
`

const Address = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.83vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 1.04vw;
  line-height: 1.73;
  text-decoration: underline;

  @media (max-width: 1439px) {
    margin-top: 12px;
    font-size: 15px;
    line-height: 26px;
  }

  svg {
    width: auto;
    height: 1.11vw;
    margin-right: 0.83vw;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};

    @media (max-width: 1439px) {
      height: 16px;
      margin-right: 12px;
    }
  }
`

const Description = styled.div`
  margin-top: 2.43vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1.04vw;
  line-height: 1.73;

  @media (max-width: 1439px) {
    font-size: 15px;
    line-height: 26px;
  }
`

const StyledButton = styled(Button)`
  margin-top: 3.47vw;

  @media (max-width: 991px) {
    margin-top: 20px;
  }
`
