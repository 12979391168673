import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Walk } from 'app/components/Icons'
import { Media } from 'app/theme/media'
import React, { memo } from 'react'

export interface Props {
  description?: string
  distance?: string
  image?: ImageProps
  title?: string
}

export const Item = memo(function Item({
  description,
  distance,
  image,
  title,
}: Props) {
  return (
    <Container>
      {image ? (
        <>
          <Media lessThan="desktopSmall">
            <MobileImage
              src={image.src}
              alt={image.alt}
              width={image.width}
              height={image.height}
            />
          </Media>

          <Media greaterThanOrEqual="desktopSmall">
            <Image {...image} />
          </Media>
        </>
      ) : null}

      <Inner>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
        {distance ? (
          <Distance>
            <Walk />
            {distance}
          </Distance>
        ) : null}
      </Inner>
    </Container>
  )
})

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 1.04vw;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 1;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const MobileImage = styled.img``

const Inner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  padding: 5vw 5vw 6.25vw;
  z-index: 2;
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 1.38vw;

  @media (max-width: 1439px) {
    font-size: 20px;
  }
`

const Description = styled.div`
  margin-top: 0.83vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1.04vw;
  line-height: 1.73;

  @media (max-width: 1439px) {
    margin-top: 12px;
    font-size: 15px;
    line-height: 26px;
  }

  @media (max-width: 991px) {
    display: none;
  }
`

const Distance = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2.08vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.69vw;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  @media (max-width: 1439px) {
    font-size: 10px;
  }

  svg {
    width: auto;
    height: 1.38vw;
    margin-right: 1.11vw;
    fill: ${({ theme }) => theme.colors.variants.neutralLight1};

    @media (max-width: 1439px) {
      height: 20px;
      margin-right: 16px;
    }
  }
`
